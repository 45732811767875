import { useEffect } from "react"
import styled from "styled-components"

import { LinearProgressBar } from "src/components/ProgressBar/LinearProgressBar"
import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { BaseWizard } from "src/ui/Wizard/BaseWizard"
import { TInlineWizard } from "src/ui/Wizard/wizardTypes"
import { getNumberOfSteps } from "src/ui/Wizard/wizardUtils"

export function InlineWizard({
  currentStep,
  steps,
  onNext,
  onBack,
  showProgress = true,
}: TInlineWizard) {
  // Set scroll position to top between step changes
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStep])

  const numberOfSteps = getNumberOfSteps(steps)
  const _showProgress = showProgress && !steps[currentStep].hideProgress

  return (
    <BaseWizard
      steps={steps}
      currentStep={currentStep}
      onNext={onNext}
      onBack={onBack}
      ProgressBar={
        _showProgress ? (
          <InlineProgressBar
            currentStep={
              currentStep < numberOfSteps - 1 ? currentStep : numberOfSteps - 1
            }
            numberOfSteps={numberOfSteps}
          />
        ) : undefined
      }
    />
  )
}

function InlineProgressBar({
  currentStep,
  numberOfSteps,
}: {
  currentStep: number
  numberOfSteps: number
}) {
  const progressStep = currentStep + 1
  return (
    <ProgressBarBox>
      <StepsBox>
        <MText>{`${progressStep}/${numberOfSteps}`}</MText>
      </StepsBox>
      <LinearProgressBar
        height="S"
        segments={[
          {
            value: (progressStep / numberOfSteps) * 100,
            type: "primary",
          },
        ]}
      />
    </ProgressBarBox>
  )
}

const ProgressBarBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.S};
`

const StepsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 40px;
  padding: ${spacing.XS};
  aspect-ratio: 1 / 1;

  background-color: ${mColors.accentPrimaryLight};
  border-radius: 9999px;
`
